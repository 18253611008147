<template>
  <div class="menu">
      <a-menu mode="horizontal" :default-selected-keys="['0']" >
          <template v-for="(item,index) in menu" :key="index">
            <a-menu-item v-if="!item.children || item.children.length <= 0" @click="jump(1, toRoute(item.order, item.id))"><span >{{ item.title }}</span></a-menu-item>
            <a-sub-menu v-else>
                <template #title >
                    <span >{{ item.title }}</span>
                </template>
                <a-menu-item v-for="children in item.children" :key="children.key" class="sub-menu-title" @click="jump(2, children.id)">
                    {{ children.title }}
                </a-menu-item>
            </a-sub-menu>

          </template>
          
      </a-menu>
  </div>

</template>
  
<script>
import router from "@/router";
import { ref } from "vue";
export default {
    name: 'Menu',
    components:{

    },
    props:{
        menu:{
          type:Array
        }
    },
    methods:{
      go_url:function(url){
        window.location.href = url
      },
      toRoute:function(order, route){
        switch(order){
          case 0:
            return "home"
          case 1:
            return "member"
          default:
            return route
        }
      },
      jump:function(type,id){
        if(id == "home"){
          // HOME
          router.push({path:"/"})
        }else if(id == "member"){
          router.push({path:"/member/"})
        }else{
          router.push({path:"/project/", query:{type:type, id:id}})
        }
          
      }
    },
    setup(){
      let selected_keys = []
      return {selected_keys}
    }

    
}
</script>

<style scoped>
  .menu{
    /* 一级菜单格高度  */
    --menu-item-height:70px;
    /* 一级菜单padding参数 */
    --menu-item-padding: 0px 40px;
    /* 一级菜单背景色 */
    --menu-item-background:#005AA0;
    /* 一级菜单文字颜色 */
    --menu-item-color:#FFF;
    /* 一级菜单hover背景色 */
    --menu-item-hover-background: #3aa0bd;
    /* 一级菜单hover字体色 */
    --menu-item-hover-color:#FFF;
    /* 一级菜单字体大小 */
    --menu-item-font-size:20px;
  }
  .menu {
    box-sizing: border-box;
    width: 100%;
  }
 
  .menu>>>.arco-menu-item{
    line-height:var(--menu-item-height);
    font-size: var(--menu-item-font-size);
  }
  .menu>>>.arco-menu{
    background: var(--menu-item-background);
    height: var(--menu-item-height)
  }

  .menu>>>.arco-menu-overflow-wrap{
    height: 100%;
  }
  .menu>>>.arco-menu-inner{
    overflow: hidden;
    padding: 0;
  }
  .menu>>>.arco-menu-pop-header span:not(.arco-menu-icon-suffix){
    height: 100%;
    display: flex;
    align-items: center;
  }
  .menu>>>.arco-menu-pop-header:hover, .menu>>>.arco-menu-item:hover{
    color: var(--menu-item-hover-color);
    background-color: var(--menu-item-hover-background)
  }
  .menu>>>.arco-menu-item.arco-menu-selected, .menu>>>.arco-menu-pop-header.arco-menu-selected,.menu>>>.arco-menu-pop-header.arco-menu-selected:hover,.menu>>>.arco-menu-item.arco-menu-selected:hover{
    color: var(--menu-item-hover-color)!important;
    background-color: var(--menu-item-hover-background) !important;
  }

  .menu>>>.arco-menu-pop-header{
    height: 100%;
    font-size: var(--menu-item-font-size);
    background: var(--menu-item-background);
    color: var(--menu-item-color);
    padding: var(--menu-item-padding);
  }
  .menu>>>.arco-menu-item{
    background: var(--menu-item-background);
    color: var(--menu-item-color);
    padding: var(--menu-item-padding);
    text-align: center;
  }
  
  
  .menu>>>.arco-menu-icon-suffix{
    display: none;
  }

  .arco-trigger-menu-item:hover{
    color: #3aa0bd!important;
    font-weight: bolder;
    background-color: #FFF!important;
    transition: .5s;
  }

  .arco-trigger-menu-item:not(:last-child){
    border-bottom: solid 1px #e6e6e6;
  }
  
  .arco-trigger-menu-item{
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
  }

  .arco-trigger-menu-item.arco-trigger-menu-selected{
    font-weight: bolder;
    color: #3aa0bd!important;
    text-align: center;
  }

</style>
  