<template>
  <div class="member-header">
    <div class="member-header-title">
      团队成员
    </div>
    <!-- <div class="breadCrumb">
      <a-breadcrumb>
        <a-breadcrumb-item>Home</a-breadcrumb-item>
        <a-breadcrumb-item>Channel</a-breadcrumb-item>
        <a-breadcrumb-item>News</a-breadcrumb-item>
      </a-breadcrumb>
    </div> -->
  </div>
  <div class="member-header-divider">
    <a-divider/>
  </div>
  <div class="member-list">
    <a-grid :cols="{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }" :rowGap="40" :colGap="60">
      <a-grid-item v-for="(item, index) in member" @click="gotoDetail(item.id)">
        <div class="member-item">
          <div class="member-avator">
            <img
              :src="item.avatar"
              alt="" style="width: 192px; height: 257px; border-radius: 5px;">
          </div>
          <div class="member-name">
            <text>{{ item.member_name }}</text>
          </div>
        </div>
      </a-grid-item>
      <!-- <a-grid-item v-for="i in 20">
        <div class="member-item">
          <div class="member-avator">
            <img
              src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp"
              alt="" style="width: 192px; height: 257px; border-radius: 5px;">
          </div>
          <div class="member-name">
            <text>张三</text>
          </div>
        </div>
      </a-grid-item> -->
    </a-grid>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";

import { getMemberListAPI } from "@/apis/member.js";
import router from "@/router";

const memberList = ref([
  {
    avator: "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp",
    memberName: "张三"
  },
  {
    avator: "https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp",
    memberName: "张三"
  }
])

const member = ref([]);

onMounted(() => {
    getMemberList()

})




const getMemberList = () => {
  getMemberListAPI().then (res => {
    member.value = res.data.results.sort((a, b) => a.member_order - b.member_order)
    console.log(member.value)
  })
}

const gotoDetail = (id) => {
  console.log(id);
  router.push('/member/' + id)
}




</script>

<style scoped>
.member-header {
  width: 70%;
  margin: auto;
  margin-top:60px;
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.member-header-divider {
  width: 70%;
  margin: auto;
}

.member-header-title {
  font-size: 40px;
  font-weight: 600;
  color: #2c3e50;
}

.breadCrumb {
  margin-top: 10px;
}


.member-list {
  width: 70%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.member-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.member-avator :hover {
  transform: scale(1.01);
  transition: all 0.1s;
  box-shadow: 2px 0px 10px #2c3e5071;
}

.member-name {
  margin-top: 10px;
}






.grid-demo-grid .demo-item,
.grid-demo-grid .demo-suffix {
  height: 48px;
  line-height: 48px;
  color: var(--color-white);
  text-align: center;
}

.grid-demo-grid .demo-item:nth-child(2n) {
  background-color: rgba(var(--arcoblue-6), 0.9);
}

.grid-demo-grid .demo-item:nth-child(2n + 1) {
  background-color: var(--color-primary-light-4);
}
</style>
