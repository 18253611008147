<template>
    <footer class="footer">
        <!-- <div class="logo-link">
            <div class="logo">
                <img src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/a8c8cdb109cb051163646151a4a5083b.png~tplv-uwbnlip3yd-webp.webp"
                    alt="" style="width: 200px; height: 80px;">
            </div>
            <div class="link">
                <a-link v-for="(item, index) in linkList" :key="index" :href="item.href">{{ item.title }}</a-link>
            </div>
        </div> -->

        <div class="container">
            <a-divider :margin="10"></a-divider>
            <p>©2023 Your Company. All rights reserved.</p>
        </div>
        <div>

        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            linkList: [
                {
                    title: '关于我们',
                    href: 'https://www.imooc.com/about'
                },
                {
                    title: '联系我们',
                    href: 'https://www.imooc.com/about/contactinfo'
                }
            ]
        }
    },
    components: {

    }
}
</script>

<style scoped>
.footer {
    background-color: #f8f8f8;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7;
    margin-top: 50px;
    position: relative;
    bottom: 0;
    width: 100%;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 20px; */
}

.link,
.container {
    text-align: center;
    margin: auto;
    width: 90%;
}

.logo-link {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin: auto;
    width: 80%;
}
</style>
