import httpInstance from "../../utils/http.js";

export function getHomeArticleListAPI(){
    return httpInstance({
        url: '/homeArticle'
    })
}

export function getMenuAPI(){
    return httpInstance({
        url: '/menu/'
    })
}

export async function getMenuSorted(look_id){
    return await getMenuAPI().then(res=>{
      

      let comp = function(obj1, obj2){
        let val1 = obj1.menu1_order;
        let val2 = obj2.menu1_order;
        if(val1 < val2){
          return -1
        }else if(val1 > val2){
          return 1
        }else{
          return 0
        }
      }

      if(res.message == "请求成功"){
        let data = res.data.results;
        data = data.sort(comp)

        return data
      }else{
        return null
      }
    })
  }

