<template>
  <div style="width: 100%;height: 200px;background: url(https://liesmars.whu.edu.cn/images/img.jpg) no-repeat top center;"></div>
  <div class="article" style="margin-left: 200px;margin-right: 200px;margin-top: 100px;">
    <a-breadcrumb separator=">>">
      <a-breadcrumb-item>
        <router-link to="/">首页</router-link>  
      </a-breadcrumb-item>
      <a-breadcrumb-item v-for="(item, index) in breadcrumb">
        <!-- <router-link :to="item.router">{{item.title}}</router-link>   -->
        <a>{{ item.title }}</a>
      </a-breadcrumb-item>
      
      <a-breadcrumb-item>正文</a-breadcrumb-item>

    </a-breadcrumb>
    <h3 class="title">{{ article.title }}</h3>
    <div style="width: 100%;">
      <p style="font-size: 16px;display: flex;">
        <div style=""><icon-calendar-clock style="font-size:20;margin-right: 5px;" />{{ article.date }}</div>
        <div style="margin-left:auto;display: flex;align-items: center;">
          
          <div style="align-items: center;display: flex;margin-right: 20px;cursor: pointer;"> 
            
            <icon-share-alt />
            <share ></share>
          </div>
          <div>
            <icon-eye />
            阅读：{{ article.read_count }}
          </div>
        </div>
      </p>
    </div>
    
    <a-divider :margin="20" />
    

    <div>
      <div v-html="article.content"></div>
    </div>
  </div>
  
</template>
  
<script>
  
  import {useRoute} from 'vue-router'
  import {onMounted, watch} from 'vue'
  import { getArticleAPI } from '@/apis/article'
  import { getMenuSorted } from '@/apis/home'
  import { Message } from '@arco-design/web-vue';
  import copy from 'copy-to-clipboard';
  import Share from "@/components/Share.vue";

import { ref } from 'vue'
  export default {
    setup(props){
      window.document.getElementById("app").style.backgroundColor='#FFF'
      
      const article = ref({
        title:"",
        date:"",
        read_count:1,
        content:''
      })

      function toCopy(){
        copy(window.location.href)
        Message.info("链接已复制到剪切板")
      }

      const route = useRoute()
      onMounted(()=>{
        if(route.params.hasOwnProperty('id')){
          getArticle(route.params.id)
        }
      })

      watch(
        () => route.params.id,
        async newId => {
          console.log("newid=",newId)
          getArticle(newId)
        }
      )

      const breadcrumb = ref([
        
      ])


      async function getArticle(id){
        getArticleAPI(id).then(res=>{
          console.log(res)
          if(res.message == "请求成功"){
            let data = res.data || null
            console.log(data)
            if(!!data){
              article.value.title = data.title
              article.value.date = data.updated_at.split(" ")[0]
              article.value.read_count = data.views_count
              article.value.content = data.content
              // if(data.hasOwnProperty("menu1") && !!data.menu1 && data.menu1 != "首页" ){
              //   breadcrumb.value.push({
              //     title:data.menu1,
              //   })
              // }
              // if(data.hasOwnProperty("menu2") && !!data.menu2){
              //   breadcrumb.value.push({
              //     title:data.menu2,
              //   })
              // }
            }
          }
        })
      }

      return {
        article,
        breadcrumb,
        toCopy
      }
    },
    name: 'ArticleView',
    components: {
      Share
    },
  }
</script>

<style>
  .article .title{
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
</style>