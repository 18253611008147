<template>
  <div class="banner" />
  <div style="margin-left: 110px; margin-right: 110px;display: flex;">
    <div style="flex: 1;width: 100%;;position: relative;">
      <SideMenu :title="title" :menu="menu" top="-70"></SideMenu>
    </div>
    <div style="width: 75%;margin-top: 20px;">
      <!-- 面包屑 -->
      <div style="display: flex;align-items: center;width: 100%;">
        <h1 style="flex: 1;">{{subtitle}}</h1>
        <a-breadcrumb separator=">>">
          <a-breadcrumb-item>
            <router-link to="/">首页</router-link>  
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, index) in breadcrumb">
            <a style="cursor: pointer;" @click="goProject(item.type, item.id)">{{ item.title }}</a>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <a-divider :size="1" />
      <!-- 文章列表 -->
      <div class="article-list">
        <template v-for="(item, index) in article_list">
          <div class="item" style="display: flex;align-items: center;" @click="goArticle(item.id)">
            <div>
              <div class="day">{{item.day}}</div>
              <div class="month">{{ item.month }}</div>
            </div>
            <a-divider direction="vertical" style="min-height: 5em;margin: 0;margin-left: 30px;margin-right: 40px;" />
            <div>
              <h2 class="title">{{ item.title }}</h2>
              <div class="abstract">{{ item.abstract }}</div>
            </div>
          </div>
        </template>
      </div>
      
      <!-- 分页 -->
      <div style="margin-top: 30px;">
          <a-pagination v-model:current="page"  :default-page-size="page_size" :total="page_total" style="justify-content: center;" @change="updateProject()"/>
      </div>


    </div>
  </div>
</template>
   
<script>

import {useRoute} from 'vue-router'
import {onMounted, watch, ref} from 'vue'
import SideMenu from '@/components/SideMenu.vue'
import {getProjectAPI as getProjectAPI} from '@/apis/project'
import { getMenuAPI } from '@/apis/home'
import router from "@/router";

  export default {
    setup(props){
      window.document.getElementById("app").style.backgroundColor='#EEE'
      const page = ref(1)
      const page_total = ref(0)
      const page_size = ref(10)
      const breadcrumb = ref([])
      const menu = ref([])
      const title = ref("")
      const subtitle = ref("")
      const article_list = ref([])

      function goArticle(id){
        router.push({path:"/article/" + id})
      }

      function goProject(type, id){
        router.push({path:"/project/", query:{type:type, id:id}})
      }

      async function getMenuLook(look_id){
        return await getMenuAPI().then(res=>{
          function toChildren(item){
            let comp = function(obj1, obj2){
              let val1 = obj1.menu2_order;
              let val2 = obj2.menu2_order;
              if(val1 < val2){
                return -1
              }else if(val1 > val2){
                return 1
              }else{
                return 0
              }
            }
            item = item.sort(comp)
            console.log(item.sort(comp))

            let result = []
            for(let i = 0;i < item.length;i++){
              result.push({
                title:item[i].menu2_name,
                id:item[i].id
              })
            }
            return result
          }

          let comp = function(obj1, obj2){
            let val1 = obj1.menu1_order;
            let val2 = obj2.menu1_order;
            if(val1 < val2){
              return -1
            }else if(val1 > val2){
              return 1
            }else{
              return 0
            }
          }

          console.log(res)
          if(res.message == "请求成功"){
            let data = res.data.results;
            data.sort(comp)
            for(let i = 0;i < data.length;i++){
              let item = data[i]
              for(let x = 0;x < item.menu2.length;x++){
                let menu2_item = item.menu2[x]
                if(menu2_item.id == look_id){
                  menu2_item.selected = true
                  return {
                    menu1_name : item.menu1_name,
                    menu1_id : item.id,
                    menu2: item.menu2
                  }
                }
              }
            }
          }

          return null
        })
      }

      function toAbstract(content){
        return content.replaceAll(/\<.+?\>/g,"").replaceAll(/&.+?;/g,"")
      }

      function toMonth(month){
        return ["January","February","March","April","May","June","July","August","September","October","November","December"][Number(month)]
      }

      async function getProject(menu_type, id, _page=page.value, _page_size=page_size.value){
        return await getProjectAPI(menu_type, id, _page, _page_size).then(async res=>{
          article_list.value.length = 0
          menu.value.length = 0
          breadcrumb.value.length = 0
          console.log(res)
          if(res.message != "请求成功"){return []}
          // 分页
          page_total.value = res.data[0].article.count
          if(res.data[0].hasOwnProperty("menu1_name")){
            let menu_name = res.data[0].menu1_name
            title.value = menu_name
            subtitle.value = menu_name
            breadcrumb.value.push({
              title:menu_name,
              id:res.data[0].id,
              type:1
            })
          }else if(res.data[0].hasOwnProperty("menu2_name")){
            let menu2_name = res.data[0].menu2_name
            subtitle.value = menu2_name
            
            let menu_look = await getMenuLook(res.data[0].id)
            console.log(menu_look)
            if(!menu_look){
              title.value = menu2_name
              breadcrumb.value.push({
                title:menu2_name,
                id:res.data[0].id,
                type:2
              })
            }else{
              title.value = menu_look.menu1_name
              breadcrumb.value.push({
                title:menu_look.menu1_name,
                id:menu_look.menu1_id,
                type:1
              })
              breadcrumb.value.push({
                title:menu2_name,
                id:res.data[0].id,
                type:2
              })

              for(let i = 0;i < menu_look.menu2.length;i++){
                let item = menu_look.menu2[i]
                menu.value.push({
                  title:item.menu2_name,
                  id:item.id,
                  type:2,
                  selected:item.selected || false
                })
              }
            }
          }

          let data = res.data[0].article || []
            data = data.result
            for(let i = 0;i < data.length;i++){
              let item = data[i]
              let date = new Date(item.updated_at)
              article_list.value.push({
                title:item.title,
                abstract:toAbstract(item.content),
                day:String(date.getDate()),
                month:toMonth(date.getMonth()),
                id:item.id
              })

            }
        })
      }

      const route = useRoute()
      function updateProject(){
        if(route.query.hasOwnProperty('id')){
          if(route.query.hasOwnProperty('type')){
            getProject(route.query.type, route.query.id) 
          }else{
            getProject(2, route.query.id)
          }
        }
      }      
      onMounted(()=>{
        updateProject()
      })

      watch(
        [() => route.query.id, () => route.query.type,],
        async ([newId,newType]) => {
          getProject(newType,newId)
        }
      )
      
      return {
        breadcrumb,
        menu,
        title,
        subtitle,
        article_list,
        page,
        page_total,
        page_size,
        updateProject,
        goArticle,
        goProject
      }
    },
    name: 'ProjectView',
    components: {
      SideMenu
    },
  }
</script>

<style>
  .article-list{
   margin-top: 40px; 
   min-height: 100px;
  }
  .article-list .month{
    color: #005aa0;
    font-size: 16px;
    text-align: center;
    transition: .5s;
  }
  .article-list .day{
    color: #005aa0;
    font-size: 30px;
    text-align: center;
    transition: .5s;
  }
  .article-list .item{
    background-color: #f4f7fa;
    padding: 30px 30px;
    transition: .5s;
  }

  .article-list .item:not(:first-child){
    margin-top: 20px;
  }

  .article-list .item:hover{
    background-color: #005aa0;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 27%);
    color: #FFF!important;
  }
  .article-list .item:hover .day,.article-list .item:hover .month{
    color: #FFF!important;
  }

  .article-list .item .abstract, .article-list .item .title{
    cursor: pointer;
  }

  .article-list .item .abstract{
    height: 50px;
    line-height: 25px;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .article-list .item .title{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .title-box{
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 30px;
    width: 270px;
    height: 120px;
    background: url('https://liesmars.whu.edu.cn/images/nav-tit-bg.jpg') no-repeat top center;
    color: #FFF;
  }
  
  .banner{
    background: url("https://liesmars.whu.edu.cn/images/2.jpg") no-repeat top center;
    height: 300px;
    width: 100%;
  }
</style>