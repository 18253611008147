<template>
    <div class="menu" id="menu" :style="{position:'absolute',top: top + 'px'}">
        <div class="title-box">
          {{title}}
        </div>
        <div class="menu">
            <template v-for="(item,index) in menu">
                <div class="menu-item" @click="goProject(item.type, item.id)">
                    <span v-if="!!item.selected" class="selected">
                        <div style="display: flex;align-items: center;">
                            <div style="flex: 1">
                                {{ item.title }}
                            </div>
                        
                            <icon-right style="font-size: 30px;margin-right: 20px;" />
                        </div>
                    </span>
                    <span v-else>
                        {{ item.title }}
                    </span>
                    
                    
                </div>
                <a-divider :size="2" style="margin: 0;border-bottom:1.5px dotted rgb(156 175 189);" />
            </template>
            
        </div>
    </div>`
</template>
      
    <script>

import router from "@/router";
    export default {
        name: 'SideMenu',
        components:{
    
        },
        props:{
            title:{
                type:String
            },
            menu:{
                type:Array
            },
            top:{
                type:String
            }
        },
        methods:{
          go_url:function(url){
            window.location.href = url
          },
            goProject:function (type, id){
                router.push({path:"/project/", query:{type:type, id:id}})
            }
        }
    
        
    }
    </script>
    
    <style scoped>
        .menu{
            width: 270px;
            background: #f6f8fd;
        }

        .menu-item{
            height: 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 18px;
            text-indent: 20px;
            cursor: pointer;
        }

        .menu-item:hover, .menu-item .selected{
            color: #005aa0;
            font-weight: bold;
        }
    </style>
      