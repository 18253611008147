import { createRouter, createWebHashHistory } from 'vue-router'

import IndexView from '../views/IndexView.vue'
import ArticleView from '../views/ArticleView.vue'
import MemberView from '../views/MemberView.vue'
import ProjectView from '../views/ProjectView.vue'
import MemberDetailView from '../views/MemberDetailView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/article/:id',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/project',
    name: 'project',
    component: ProjectView
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/member/:id',
    name: 'member-detail',
    component: MemberDetailView
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
