import httpInstance from "../../utils/http.js";

export function getMemberListAPI () {
    return httpInstance({
        url: '/member/',
        method: 'get'
    })
}

export function getMemberDetailAPI (id) {
    return httpInstance({
        url: `/member/${id}/`,
        method: 'get'
    })
}