<template>
    <div class="container">
        <a-popover trigger="click">
            <a-button type="text" @click="shareLink">分享</a-button>
            <template #content>
                <div>
                    <!-- <icon-font type="icon-weibo" :size="20"/> -->
                    <icon-weibo size="20" />
                    <!-- <text class="text">分享到微博</text> -->
                    <a-button type="text" @click="toWeibo">分享到微博</a-button>
                </div>
                <div ><icon-qq-zone size="20" />
                    <!-- <text class="text">分享到QQ空间</text> -->
                    <a-button type="text" @click="toQQ">分享到QQ</a-button>
                </div>
                <!-- <div @click="toWechat"><icon-wechat size="20" /><text class="text">分享到微信</text></div> -->
            </template>
        </a-popover>

    </div>
</template>

<script>
export default {
    name: 'Share',

    components: {
        // IconFont

    }
}
</script>


<script setup>
import { ref } from 'vue';
import { Icon } from '@arco-design/web-vue';


const url = ref("");

const shareLink = () => {
    console.log("share")
    url.value = encodeURIComponent(window.location.href);
    console.log(url.value)
}

const toWeibo = () => {
    console.log("weibo")
    window.open('http://service.weibo.com/share/share.php?url=' + url.value + '&title=' + '分享链接' + '&pic=&searchPic=false&style=simple', '_blank');
}

const toQQzone = () => {
    console.log("qqZone")
    windows.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url.value + '&title=' + '分享链接' + '&pics=&summary=&desc=&site=&style=203&width=98&height=22&otype=share', '_blank');
}

const toQQ = () => {
  console.log("qq")
  window.open('https://connect.qq.com/widget/shareqq/index.html?url=' + url.value + '&title='+ '分享链接' + '&pic=&searchPic=false&style=simple', '_blank');
}

const toWechat = () => {
    console.log("wechat")
    console.log(url.value)
}

// const copy = async () => {
//     try {
//         await toClipboard('Any text you like')
//         console.log('Copied to clipboard')
//     } catch (e) {
//         console.error(e)
//     }
// }

// const IconFont = Icon.addFromIconFontCn({ src: '//at.alicdn.com/t/c/font_4229078_zhdi5uk1qlj.js' });



</script>

<style scoped>
.text {
    margin-left: 10px;
}
</style>
