<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import { getMenuSorted } from './apis/home'
import { ref } from 'vue'

export default {
  setup() {
    const toolbar_menu = ref([
    ])
    
    async function getMenu(){
      function toChildren(item){
        let comp = function(obj1, obj2){
          let val1 = obj1.menu2_order;
          let val2 = obj2.menu2_order;
          if(val1 < val2){
            return -1
          }else if(val1 > val2){
            return 1
          }else{
            return 0
          }
        }
        item = item.sort(comp)

        let result = []
        for(let i = 0;i < item.length;i++){
          result.push({
            title:item[i].menu2_name,
            id:item[i].id
          })
        }
        return result
      }

      let menu_sorted = await getMenuSorted()
      toolbar_menu.value.length = 0
      for(let i = 0;i < menu_sorted.length;i++){
        let item = menu_sorted[i]
        
        toolbar_menu.value.push({
          id:item.id,
          title:item.menu1_name,
          children:toChildren(item.menu2),
          order:item.menu1_order
        })
      }

    }

    getMenu()

    return {
      toolbar_menu
    }
  },

  components: {
    Menu,
    Footer

  }
}

</script>



<template>
  <div style="padding-left: 100px;background: #005AA0;">
    <Menu :menu="toolbar_menu"/>
  </div>
  
  <router-view/>
  <Footer/>
  
  
  
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


html{
    background-color: #ffffff;
  }
</style>
