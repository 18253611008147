/**
    新闻组件
*/
<template>
    <dl class="news" v-if="direction=='out'" @click="goArticle()">
        <dt :style="{height: height + 'px',overflow: 'hidden', 'min-height':height + 'px'}" @mouseover="hover_img">
            <a-image class="i" ref="img" :src="image_url" fit="fill" :preview="false" />
        </dt>
        <dd class="news_desp">
            <div style="display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                {{ title }}
            </div>
            
        </dd>
    </dl>

    <dl class="news_in" v-if="direction=='in'"  @click="goArticle()">
        <dt :style="{height: (Number(height) + 100) + 'px',overflow: 'hidden','min-height': (Number(height) + 100) + 'px'}">
            <a-image :title="title" :src="image_url" fit="fill" :preview="false" />
        </dt>
    </dl>
    
    
</template>
      
    <script>
    
    import router from "@/router";
    import { ref } from "vue";
    export default {
        name: 'News',
        setup(){

        },
        components:{
    
        },
        props:{
            /* 图片地址 */
            image_url:{
                type:String
            },
            /* 标题 */
            title:{
                type:String
            },
            /* 跳转链接 */
            href:{
                type:String
            },
            /* 标题显示区域 in为内 out为外 */
            direction:{
                type:String
            },
            /* 图片高度
                如果标题在外面 文字高度固定为100px（文字60px+2*20px的padding）
                如果标题在里面 图片高度自动+100px以便对齐新闻组
                宽度自动对齐父控件 图片拉伸自适应
            */
            height:{
                type:Number
            },
            id:{
                type:Number
            }
            
        },
        methods:{
            go_url:function(url){
                window.location.href = url
            },
            goArticle:function(){
                if(!this.id){
                    return
                }
                router.push({path:"/article/" + this.id})
            }
        }
    }
    </script>
    
    <style scoped>

        dl{
            margin: 0;
        }

        dd{
            margin: 0;
            height: 60px;
        }
        
        .news, .news_in{
            background: #fff;
            color: #666;
            cursor: pointer;
        }

        .news:hover{
            color: #2bb7b3!important;
        }

        .news_desp{
            padding: 20px;
            font-size: 20px;
            line-height: 1.8;
            font-weight: bold;
            word-break: break-all;
            text-align: left;   
        }

        .news>>>.arco-image-img, .news_in>>>.arco-image-img{
            object-fit: fill;
            width: 100%;
            min-width: 100%;
            height: 100%;
            transition: all .35s;
        }

        .news>>>.arco-image-img:hover, .news_in>>>.arco-image-img:hover{
            transform: scale(1.1);
        }

        .news>>>.arco-image, .news_in>>>.arco-image{
            width: 100%;
            min-width: 100%;
            height: 100%;
        }

        .news_in>>>.arco-image-footer-caption-title{
            color: #fff;
            font-size: 20px;
            line-height: 1.8;
            font-weight: bold;
            word-break: break-all;
            text-align: left;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

    </style>
      