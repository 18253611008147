<template>
<div class="article" style="margin-left: 200px;margin-right: 200px;">
    <a-breadcrumb separator=">>" style="margin-top: 30px;">
      <a-breadcrumb-item>
        <router-link to="/">首页</router-link>  
      </a-breadcrumb-item>
      <a-breadcrumb-item v-for="(item, index) in breadcrumb">
        <router-link :to="item.router">{{item.title}}</router-link>  
      </a-breadcrumb-item>
      
      <a-breadcrumb-item>{{ member_name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <h3 class="title">{{ member_name }}</h3>
    <div style="width: 100%;">
      <p style="font-size: 16px;display: flex;">
        <!-- <div style=""><icon-calendar-clock style="font-size:20;margin-right: 5px;" />{{ date }}</div> -->
        <div style="margin-left:auto;display: flex;align-items: center;">
          <div style="margin-right: 20px;cursor: pointer; display: flex; flex-direction: row; align-items: center;">
            <icon-share-alt size="20" />
            <share ></share>
          </div>
        </div>
      </p>
    </div>
    
    <a-divider :margin="20" />
    

    <div class="content">
      <div v-html="member_description"></div>
    </div>
  </div>
</template>

<script >
export default {
    name: 'MemberDetailView',
  components: {

  }
}
</script>

<script setup>
import { getMemberDetailAPI } from "@/apis/member";
import { t } from "@wangeditor/editor";
import {ref, onMounted} from "vue";
import {useRoute} from "vue-router";
import Share from "@/components/Share.vue";

const route = useRoute();
const member_id = ref(route.params.id);
const member_name = ref("");
const member_description = ref("");
const url = ref("");
const pathName = ref("");

const breadcrumb = [
        {
          title:"团队成员",
          router:"/member"
        },
      ]

// const test_id = ref('3f5befd1-1576-4974-be19-88e3407cf7ec');

onMounted(() => {
  getdetail();
  // console.log(member_id.value)

})


const getdetail = () => {
    try {
        getMemberDetailAPI(member_id.value).then(res => {
        member_description.value = res.data.member_description;
        member_name.value = res.data.member_name;
        console.log("获取成员详情成功")
    })
    }
    catch (err) {
        console.log(err)
    }

}

const shareLink = () => {
  console.log("share")
  url.value = encodeURIComponent(window.location.href);
  console.log(url.value)
  // toWeibo();
  // toQQ();
  toQQzone();
}

const toWeibo = () => {
  console.log("weibo")
  window.open('http://service.weibo.com/share/share.php?url=' + url.value + '&title='+ '分享团队成员：' + member_name.value + '&pic=&searchPic=false&style=simple', '_blank');
}

const toQQ = () => {
  console.log("qq")
  window.open('https://connect.qq.com/widget/shareqq/index.html?url=' + url.value + '&title='+ '分享团队成员：' + member_name.value + '&pic=&searchPic=false&style=simple', '_blank');
}

const toQQzone = () => {
    console.log("qqZone")
    windows.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url.value + '&title=' + '分享链接' + '&pics=&summary=&desc=&site=&style=203&width=98&height=22&otype=share', '_blank');
}


</script>

<style scoped>
  .article .title{
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .content {
    height: 70vh;
  }

</style>
