<template>
  <div class="index-news">
    <a-row style="margin-top: 100px;margin-left: 200px;margin-right: 200px;" :gutter="12">
      <a-col :span="12">
        <a-space direction="vertical" :size="16" style="width: 100%;">
          <News height=300 direction="in" :id="home_article_list.home_left_big.id" :image_url="home_article_list.home_left_big.image_url" :title="home_article_list.home_left_big.title"></News>
          <a-row :gutter="12">
            <a-col :span="12">
              <News height=200 direction="out" :id="home_article_list.home_left_mini_1.id" :image_url="home_article_list.home_left_mini_1.image_url" :title="home_article_list.home_left_mini_1.title"></News>
            </a-col>
            <a-col :span="12">
              <News height=200 direction="out" :id="home_article_list.home_left_mini_2.id" :image_url="home_article_list.home_left_mini_2.image_url" :title="home_article_list.home_left_mini_2.title"></News>
            </a-col>
          </a-row>
        </a-space>
      </a-col>

      <a-col :span="12">
        <a-space direction="vertical" :size="16"  style="width: 100%">
          <a-row :gutter="12">
            <a-col :span="12">
              <News height=200 direction="out" :id="home_article_list.home_right_mini_1.id" :image_url="home_article_list.home_right_mini_1.image_url" :title="home_article_list.home_right_mini_1.title"></News>
            </a-col>
            <a-col :span="12">
              <News height=200 direction="out" :id="home_article_list.home_right_mini_2.id" :image_url="home_article_list.home_right_mini_2.image_url" :title="home_article_list.home_right_mini_2.title"></News>
            </a-col>
          </a-row>
          <News height=300 direction="in" :id="home_article_list.home_right_big.id" :image_url="home_article_list.home_right_big.image_url" :title="home_article_list.home_right_big.title"></News>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>
  
<script>

import News from "@/components/News.vue"

import { ref, reactive } from "vue";
import router from "@/router";
import {getProjectAPI as getProjectAPI} from '@/apis/project'
import { getMenuAPI, getMenuSorted } from '@/apis/home'
import { IconFolderDelete } from "@arco-design/web-vue/es/icon";

  export default {
    setup(props){

      window.document.getElementById("app").style.backgroundColor='#EEE'
      const home_article_list = ref({
        "home_left_big":{

        },
        "home_left_mini_1":{

        },
        "home_left_mini_2":{
        },
        "home_right_big":{
        },
        "home_right_mini_1":{
        },
        "home_right_mini_2":{
        }
      })

      async function getHomeId(){
        let menu = await getMenuSorted()
        for(let i = 0;i < menu.length;i++){
          let item = menu[i]
          if(item.menu1_order == 0){
            return item.id
          }
        }
      }

      async function getHomeArticleList(){
        let homeid = await getHomeId()
        getProjectAPI(1, homeid).then(res=>{
          console.log(res)
          if(res.message == "请求成功"){
            let data = res.data[0].article.result
            console.log(data)
            for(let i = 0;i < data.length;i++){
              let item = data[i]
              switch(item.area){
                case 1:
                  item.area = "home_left_big"
                  break
                case 2:
                  item.area = "home_left_mini_1"
                  break
                case 3:
                  item.area = "home_left_mini_2"
                  break
                case 4:
                  item.area = "home_right_mini_1"
                  break
                case 5:
                  item.area = "home_right_mini_2"
                  break
                case 6:
                  item.area = "home_right_big"
                  break
              }
              home_article_list.value[String(item.area)] = {
                title:item.title,
                id:item.id,
                image_url:item.post_image
              }
            }
          }
        })
        
        
      }

      getHomeArticleList()
      
      return {
        home_article_list
      }
    },
    name: 'IndexView',
    components: {
      News
    },
  }
</script>

<style>
    #app{
    background:#EEE
  }
  
</style>