import httpInstance from "../../utils/http.js";

export function getProjectAPI (menu_type, id, page=1, page_size=10) {
    let params = {
        page:page,
        page_size:page_size
    }
    if(menu_type == 2){
        params.menu2_id = id
    }else{
        params.menu1_id = id
    }
    return httpInstance({
        url: '/menu/get_articles/',
        params:params
    })
}